import { Directive, ElementRef, Input, SimpleChanges, inject } from '@angular/core';

@Directive({
  selector: '[appDynamicTextColor]'
})
export class DynamicTextColorDirective {
  @Input() backgroundImage: string
  
  el = inject(ElementRef)

  ngOnInit() {
    this.setBackgroundImage(this.backgroundImage);
  }

  setBackgroundImage(imageUrl: string) {
    const img = new Image();
    img.crossOrigin = 'Anonymous'; // Important for loading cross-origin images
    img.src = imageUrl;
    img.onload = () => {
      const color = this.getAverageRGB(img);
      const brightness = (color.r * 299 + color.g * 587 + color.b * 114) / 1000;
      this.el.nativeElement.style.color = brightness > 125 ? 'black' : 'white';
    };
  }

  getAverageRGB(img: HTMLImageElement) {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const context = canvas.getContext('2d');
    context.drawImage(img, 0, 0, img.width, img.height);
    const data = context.getImageData(0, 0, img.width, img.height).data;
    let r = 0, g = 0, b = 0, count = 0;

    for (let i = 0; i < data.length; i += 4) {
      r += data[i];
      g += data[i + 1];
      b += data[i + 2];
      count++;
    }

    return { r: r / count, g: g / count, b: b / count };
  }
}
