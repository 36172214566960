<footer class="footer text-muted text-center text-small py-4">
  <img src="./assets/images/logo_gab.svg" class="mb-3" height="50px" width="100%" loading="lazy"
    alt="Logo GAB Tamagnini S.R.L" />
  <ul class="list-inline">
    <li class="list-inline-item">
      <a href="https://www.gabtamagnini.it/assistenza/">Assistenza</a>
    </li>
    <li class="list-inline-item">
      <a href="https://www.gabtamagnini.it/">Sito Web</a>
    </li>
    <li class="list-inline-item">
      <a href="https://www.gabtamagnini.it/contatti/">Contattaci</a>
    </li>
  </ul>
  <p class="mb-1">© {{ date | date: "yyyy" }} GAB Tamagnini S.R.L.</p>
</footer>
