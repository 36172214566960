import { Topping } from '../models/topping.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Product } from '../models/product.model';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private cart: Cart;
  constructor() {
    this.cart = JSON.parse(localStorage.getItem('VO_CART')) || new Cart();
    this.validateCartStructure();
  }

  public isCartEnabled = new BehaviorSubject(false);

  /**
   * Validates the cart structure and resets it if invalid
   */
  validateCartStructure(): void {
    if (this.cart && this.cart.company && this.cart.shopId && this.cart.type && this.cart.products) {
      return;
    }
    // resets the cart if the structure is invalid
    this.clearCart();
    this.syncCart();
  }


  addToCart(product: Product, isQuantityForced: boolean = false): any {
    if (!product.varianti || product.varianti && product.varianti.length === 0) {
      const productInCartNoToppings = this.cart.products.find(
        (d) => d.id === product.id && (!d.varianti || d.varianti && d.varianti.length === 0)
      );

      if (productInCartNoToppings !== undefined) {
        if (isQuantityForced) { // product quantity is likely changed from model-product component
          productInCartNoToppings.qta += product.qta;
        }

        if (!isQuantityForced) { // product quantity is likely changed from cart component
          productInCartNoToppings.qta += 1;
        }
        this.calculateCartAmount();
        return;
      }
    }

    const productIndex = this.cart.products.indexOf(product);
    const productInCart = this.cart.products[productIndex];
    if (productInCart !== undefined) {
      productInCart.qta += 1;
      this.calculateCartAmount();
      return;
    }

    this.cart.products.push({ ...product });
    this.calculateCartAmount();
  }

  removeFromCart(product: Product) {
    const productIndex = this.cart.products.indexOf(product);
    const productInCart = this.cart.products[productIndex];

    if (productInCart === undefined) {
      return;
    }

    if (productInCart.qta === 1) {
      this.cart.products.splice(productIndex, 1);
    }

    if (productInCart.qta >= 1) {
      productInCart.qta += -1;
    }

    this.calculateCartAmount();

  }

  setCart(company: string, shopId: string, type: string): void {
    if (!this.checkCart(company, shopId, type)) {
      this.clearCart();
      this.cart.company = company;
      this.cart.shopId = shopId;
      this.cart.type = type;
      this.syncCart();
    }
  }

  checkCart(piva: string, cfg: string, tipo: string): boolean {
    if (piva !== this.cart.company) {
      return false;
    } else if (cfg !== this.cart.shopId) {
      return false;
    } else if (tipo !== this.cart.type) {
      return false;
    }
    return true;
  }

  removeTopping(product: Product, topping: Topping): void {
    let products = this.cart.products;
    const index: number = products.indexOf(product);

    products[index].varianti = products[index].varianti.filter((d) => d.id !== topping.id);

    this.calculateCartAmount();
  }

  calculateCartAmount(): void {
    const { total } = this.cart.products.reduce(
      ({ total }, { qta, prezzo, varianti, um }) => {
        if (varianti) {
          varianti.forEach((variante) => {
            prezzo += variante.prezzo;
          });
        }
        return {
          total: total + ((um !== 'PZ' ? prezzo / 1000 : prezzo) * qta),
        };
      },
      { total: 0 }
    );
    this.cart.amount = total;
    this.syncCart();
  }

  getProductQuantity(product: Product): number {
    const productIndex = this.cart.products.indexOf(product);
    const productInCart = this.cart.products[productIndex];

    if (productInCart === undefined) {
      return 0;
    }

    return productInCart.qta;
  }

  getProductsQuantity(idProduct: number) {
    let quantity = this.cart.products
      .filter(product => product.id === idProduct)
      .reduce((sum, current) => sum + current.qta, 0);

    return quantity;
  }


  get productsQuantity() {
    return this.cart.products
      .reduce((sum, current) => sum + current.qta, 0);

  }

  get totale(): number {
    return this.cart.amount;
  }

  getItems(): Cart {
    return this.cart;
  }

  removeAllProducts() {
    this.cart.products = [];
    this.cart.amount = 0;
    this.syncCart();
  }

  clearCart(): void {
    this.cart = new Cart();
  }

  syncCart(): void {
    localStorage.setItem('VO_CART', JSON.stringify(this.cart));
  }
}

export class Cart {
  company: string;
  shopId: string;
  type: string;
  products: Product[] = [];
  amount = 0;
}
