import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faInstagram, faFacebook, faWhatsapp, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faSquare, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faSquare as farSquare, faCreditCard } from '@fortawesome/free-regular-svg-icons';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HrefDirective } from '../core/directives/href.directive';
import { FillPipe } from '../core/pipe/fill.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { FooterComponent } from './components/footer/footer.component';
import { DynamicTextColorDirective } from '../core/directives/dynamic-text-color.directive';

@NgModule({
  declarations: [
    TopBarComponent,
    HrefDirective,
    DynamicTextColorDirective,
    FillPipe,
    FooterComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatBadgeModule
  ],
  exports: [
    TopBarComponent,
    CommonModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    LazyLoadImageModule,
    NgxSkeletonLoaderModule,
    FontAwesomeModule,
    MatIconModule,
    MatBottomSheetModule,
    MatToolbarModule,
    MatButtonModule,
    MatSnackBarModule,
    MatBadgeModule,
    HrefDirective,
    FillPipe,
    TranslateModule,
    FooterComponent,
    DynamicTextColorDirective
  ]
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faSquare,
      farSquare,
      faInstagram,
      faFacebook,
      faWhatsapp,
      faTwitter,
      faLinkedin,
      faCreditCard,
      faInfoCircle
    );
  }
}
