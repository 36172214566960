import { Injectable } from '@angular/core';

export class ShopInfo {
  constructor(
    public id?: number,
    public piva?: string,
    public insegna?: string,
    public slogan?: string,
    public logo?: string,
    public bg_image?: string,
    public moduli?: Moduli,
    public lingue?: Lingue[],
    public position?: Position,
    public contacts?: Contacts
  ) {}
}

class Moduli {
  constructor(
    public id: number,
    public asporto: boolean,
    public takeaway: boolean,
    public ecom: boolean,
    public menu: boolean,
    public prenotazione: boolean,
    public ingredienti: boolean,
    public ticketing: boolean,
    public ordine_menu: number,
    public pagamento_menu: number
  ) {}
}

class Position {
  indirizzo: string;
  cap: string;
  citta: string;
  provincia: string;
  nazione: string;
  lat: string;
  lng: string;

  constructor(indirizzo: string, cap: string, citta: string, provincia: string, nazione: string, lat: string, lng: string) {
    this.indirizzo = indirizzo;
    this.cap = cap;
    this.citta = citta;
    this.provincia = provincia;
    this.nazione = nazione;
    this.lat = lat;
    this.lng = lng;
  }
}

class Contacts {
  tel: string;
  fax: string;
  mail: string;
  facebook: string;
  instagram: string;
  twitter: string;
  sito: string;
  linkedin: string;
  whatsapp: string;

  constructor(tel: string, fax: string, mail: string, facebook: string, instagram: string, twitter: string, sito: string, linkedin: string, whatsapp: string) {
    this.tel = tel;
    this.fax = fax;
    this.mail = mail;
    this.facebook = facebook;
    this.instagram = instagram;
    this.twitter = twitter;
    this.sito = sito;
    this.linkedin = linkedin;
    this.whatsapp = whatsapp;
  }
}

class Lingue {
  constructor(public desc: string) {}
}

@Injectable({
    providedIn: 'root'
  })
  export class ProfiloAdapter {
    adapt(item: any): ShopInfo {
        return new ShopInfo(item.id, item.piva, item.insegna, item.slogan,
             item.logo, item.bg_color, item.bg_image, item.moduli, item.lingue, item.tavolo);
    }
}
